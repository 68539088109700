import React, { useEffect, useState } from 'react'
import Wrapper from '../components/wrapper'

const NotFoundPage = ({ location }) => {
  const [isMount, setMount] = useState(false)
  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return <div>loading!</div>
  }
  return (
    <Wrapper location={location} title="404 Not found | MedReps.com">
      <div className="text-center w-full min-h-[400px] flex justify-center items-center">
        Page not found
      </div>
    </Wrapper>
  )
}
export default NotFoundPage
